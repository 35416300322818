import React, {useEffect, useState} from 'react';
import './EventItem.scss';
import utils from "../../../../../../helpers/utils";
import TextViewer from "../../../../../../helpers/textViewer";
import Icon from "@ant-design/icons";
import moment from "moment";
import SingletoneStoreClass from "../../../../../../Store/Store";

const store = SingletoneStoreClass.getInstance();


const EventItem = (props) => {

    const { date, name, description, link, by, where, icon, isEventPass, isNearestEvent, plasmaMode=false, minyan } = props;

    const [open, setOpen] = useState(false);

    if(plasmaMode) {
        return <div className={`display-event-container ${isEventPass ? 'past-date' : ''}${isNearestEvent ? 'is-nearest-event' : ''}` } id={isNearestEvent ? 'nearest-event-time' : ''}>
            <div className={'display-event-name-and-icon'}>
                <Icon component={icon} className={'event-icon'}/>
                <div className={'display-event-name'}> <TextViewer text={name}/></div>
            </div>
            <div className={'display-event-time'}>
                {utils.dateToHH_MM(date)}
            </div>
            <div className={'divider-event'}/>
        </div>
    }

    return <div className={`display-event-container ${isEventPass ? 'past-date' : ''}${isNearestEvent ? 'is-nearest-event' : ''}` } id={isNearestEvent ? 'nearest-event-time' : ''}>
        <div className={'display-event-empty'}/>
        <Icon component={icon} className={'event-icon'}/>
        <div className={'display-event-empty'}/>
        <div className={'display-event-time'}>
            {utils.dateToHH_MM(date)}
        </div>
        <div className={'display-event-empty-middle'}/>
        <div className={'display-event-body'}>
            <div className={'display-event-name'}> <TextViewer text={name}/></div>
            {description ? <div className={'display-event-description'}>{description}</div> : null}
            {minyan ? <div className={'display-event-description'}>{minyan}</div> : null}
            {open ? <div className={'display-event-description-more'}>
                {where ? <div className={'display-event-where'}>{where}</div> : null}
                {by ? <div className={'display-event-by'}>{by}</div> : null}
            </div> : null}

        </div>
        <div className={'display-event-more-less'} onClick={()=>setOpen(!open)}>{open ?  <TextViewer text={(where || by) ? "less" : ''}/> : <TextViewer text={(where || by) ? "more" : ''}/>}</div>
        <div className={'display-event-actions'}>
            {link ? <div className={'display-event-action'} onClick={()=> window.open(link, "_blank")}>Zoom</div> : null}
            {!isEventPass ? <div className={'display-event-action save-to-calender'} onClick={async ()=>{
                const toUserTimezone = (d) => moment(d).format("YYYYMMDDThhmm00Z")
                const endDate = new Date(date.getTime() + 900000); //15min=900000ms (15*60*1000)
                let { name: synagogueName } = await store.getSynagoguePublicValues({keys: ['name']});

                let details = `\n${synagogueName} - ${TextViewer({text: name, returnString: true})}`;
                if(description) details += `\n${TextViewer({text: 'description', returnString: true, addedString: description, addChar:':'})}`;
                if(minyan) details += `\n${TextViewer({text: 'minyan', returnString: true, addedString: minyan, addChar:':'})}`;
                if(where) details += `\n${TextViewer({text: 'where', returnString: true, addedString: where, addChar:':'})}`;
                if(by) details += `\n${TextViewer({text: 'by', returnString: true, addedString: by, addChar:':'})}`;
                details += '\n';
                details += 'פרטים נוספים: ';
                details += utils.getAppUrl();
                const urlLinkToCalender = encodeURI(`https://www.google.com/calendar/event?action=TEMPLATE&dates=${toUserTimezone(date)}/${toUserTimezone(endDate)}&text=${TextViewer({text: name, returnString: true})}&details=${details}`);
                console.log(urlLinkToCalender)
                window.open(urlLinkToCalender, "_blank");
            }}><TextViewer text={'save_to_calender'}/></div> : null}
        </div>
    </div>
}


export default EventItem;